<template>
  <div>
    <ClientHeader :client="client" />
    <ClientSupportTickets type="extended" :clientId = clientId />
  </div>
</template>

<script>
import ClientSupportTickets from "@/components/Client/ClientSupportTickets";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "SupportTickets",

  components: { ClientSupportTickets, ClientHeader },
  data: () => ({
    clientId: "",
    client:null,
  }),
  methods:{
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
  },
  mounted ()  {
    this.clientId = this.$route.params.clientId
    this.getCurrentClient()
  }
};
</script>
